import React, { Component } from 'react';
import APIController from '../APIController'

export default class PrivacyPolicy extends Component {

    render() {
        return (
            <div>

                <h2>Kim jesteśmy</h2>
                <p><strong>WINNICA ŚWIDNICKA</strong><br />ul. Kraszowicka 23<br />tel. 662 043 709</p>
                <p>Adres naszej strony internetowej to: https://www.sklep.winnicaswidnicka.eu</p>
                <p>Administratorem danych osobowych jest Winnica Świdnicka Jolanta Gut z siedzibą w Świdnicy (kod pocztowy 58-100) przy ul. Kraszowickiej 23, wpisana do Centralnej Ewidencji i Informacji o Działalności Gospodarczej Rzeczypospolitej Polskiej, zwana dalej Sklepem.</p>
                <p>NIP 8841590843</p>

                <h2>Jakie dane osobiste zbieramy i dlaczego je zbieramy</h2>
                <div>
                    <div>
                    <p>Dane osobowe przetwarzane są w celu obsługi zamówień składanych poprzez stronę internetową www.sklep.winnicaswidnicka.eu, w tym informowania klientów o szczegółach złożonych zamówień, statusach zamówień i ewentualnego kontaktu związanego z realizacją zamówień. Dane osobowe wykorzystywane są również do udzielania odpowiedzi na zapytania klientów lub wysyłanie wiadomości informacyjnych w ramach subskrypcji usługi newsletter.</p>
                    <p>Dane osobowe przetwarzane są zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych.</p>
                    </div>
                </div>
                <p>Dane osobowe zostają podane dobrowolnie za pośrednictwem formularzy rejestracyjnych Sklepu.</p>
                <p>Podawane dane osobowe zawierają imię, nazwisko, adres, numer telefonu, adres email oraz ewentualne dane do faktury. Dane te są niezbędne do prawidłowej realizacji zamówień. Sklep zapisuje ponadto adres IP urządzenia osoby podającej swoje dane osobowe.</p>
                <p>Dane osobowe umieszczone są na serwerze podmiotu trzeciego, z którego usług korzysta Sklep*.</p>
                <p>W związku z realizacją zamówień dane osobowe przekazywane są firmom spedycyjnym* poprzez systemy elektroniczne tych firm służące do generowania etykiet adresowych i jedynie w celu realizacji dostawy wysyłanych towarów. Firmy te mogą wysyłać do adresatów przesyłek emaile informujące o statusie doręczenia przesyłki lub wykorzystywać numer telefonu adresata do kontaktu w celu ustalenia szczegółów doręczenia przesyłki.</p>
                <p>Poza sytuacjami opisanymi powyżej, dane osobowe nie są udostępniane w żadnej formie żadnym podmiotom trzecim.</p>
                <p>Dane osobowe nie są poddawane tzw. profilowaniu, ani nie służą do budowania żadnych odrębnych baz danych. </p>
                <p><strong>Korzystamy z usługi Google Analytics oraz Facebook Pixel do analizy ruchu na naszej stronie internetowej</strong>.</p>           
                <p>Każda osoba podająca swoje dane osobowe poprzez formularze Sklepu ma możliwość wglądu do nich, poprawiania ich, cofnięcia zgody na ich przetwarzanie w dowolnym zakresie, a także całkowitego ich usunięcia.&nbsp;</p>
                <p><strong>Każdej osobie, której dane osobowe są przetwarzane, przysługuje prawo wniesienia skargi do organu nadzorczego (Prezesa UODO).</strong></p>
                <p>Dane osobowe podane poprzez formularz kontaktowy lub inne formularze będą wykorzystane wyłącznie w celu udzielania informacji związanych z przedmiotem zapytania i po tym fakcie nie będą w żaden sposób przetwarzane.</p>
                <p>W przypadku gdy będą tego wymagały przepisy prawa dane osobowe mogą zostać udostępnione uprawnionym do tego instytucjom państwowym</p>
                <p>Komunikacja między komputerem użytkownika, a serwerem Sklepu jest szyfrowana z użyciem protokołu SSL (Secure Socket Layer).</p>
                <p>O każdej przewidywanej zmianie powyższej Polityki Prywatności co do zasad lub celów przetwarzania danych, osoby, których dane osobowe są przetwarzane, zostaną poinformowane z wyprzedzeniem 14 dni. W przypadku braku akceptacji zmian istnieje możliwość cofnięcia zgody na przetwarzanie danych osobowych w dowolnym zakresie, a także całkowitego ich usunięcia.</p>
                <p></p>

                <h2>Informacje dodatkowe</h2>

                <h3>Media</h3>
                <p>Jeśli jesteś zarejestrowanym użytkownikiem i wgrywasz na witrynę obrazki, powinieneś unikać przesyłania obrazków z tagami EXIF lokalizacji. Odwiedzający stronę mogą pobrać i odczytać pełne dane lokalizacyjne z obrazków w witrynie.</p>
                <p>Przechowujemy dane z formularzy kontaktowych tylko na potrzeby danych zapytań.</p>

                <h3>Osadzone treści z innych witryn</h3>
                <p>Artykuły na tej witrynie mogą zawierać osadzone treści (np. filmy, obrazki, artykuły itp.). Osadzone treści z innych witryn zachowują się analogicznie do tego, jakby użytkownik odwiedził bezpośrednio konkretną witrynę.</p>
                <p>Witryny mogą zbierać informacje o tobie, używać ciasteczek, dołączać dodatkowe, zewnętrzne systemy śledzenia i monitorować twoje interakcje z osadzonym materiałem, włączając w to śledzenie twoich interakcji z osadzonym materiałem jeśli posiadasz konto i jesteś zalogowany w tamtej witrynie.</p>
            </div>
        )
    }
}
