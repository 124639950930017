import React, { Component } from 'react';
import APIController from '../APIController'
import { UserContext } from '../UserContex'
import GridComponent from './base/GridComponent';

export default class AdminPanelProducts extends GridComponent {

    className = 'adminpanel-products';
    mapping = {
        name: 'Nazwa',

    };
    urlApi = 'api/product';
    urlDetails = '/panel/produkt';
    urlBack = '/panel';

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    loadData = async () => {
        console.log("Pobieranie...")
        const response = await this.apiController.get(this.urlApi);
        if (response.ok) {
            console.log("Pobrano dane")
            const entities = await response.json();
            this.setState({
                entities: entities
            })
        }
    }
}