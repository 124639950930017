import React, { Component } from 'react';
import BaseComponent from './base/BaseComponent';
import EmailValidator from '../helpers/EmailValidator'

export default class Account extends BaseComponent {
    state = {
        loading: true
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    loadData = async () => {
        const response = await this.apiController.get('api/user/account');
        if (response.ok) {
            const data = await response.json();
            this.setState({ data: data })
        }
        else {
            this.props.history.push('/');
        }
    }

    render() {
        if (this.state.loading)
            return null;

        return (
            <div className="account">
                <h2>Konto</h2>
                <button onClick={() => this.logout()}>Wyloguj</button>
                {this.renderAccountInformations()}
                {this.renderAccountNewsletter()}
                {this.renderAccountChangePassword()}
                {this.renderAccountOrders()}
            </div>
        )
    }

    renderAccountInformations() {
        return (
            <div className="account_informations">
                <h3>Dane użytkownika</h3>
                <p>Email: {this.state.data.email}</p>
                <p>Telefon: {this.state.data.phoneNumber}</p>
            </div>
            )
    }

    renderAccountChangePassword() {
        return (
            <div className="account_change-password">
                <h3>Zmiana hasła</h3>
                <label>Stare hasło</label><input type="password" />
                <label>Nowe hasło</label><input type="password" />
                <label>Powtórz nowe hasło</label><input type="password" />
            </div>
        )
    }

    renderAccountNewsletter() {
        return (
            <div className="account_newsletter">
                <h3>Newsletter</h3>
            </div>
            )
    }


    renderAccountOrders() {
        return (
            <div className="account_orders">
                <h3>Zamówienia</h3>
            </div>
            )

    }

    logout() {
        this.context.logout();
        this.props.history.push('/');
    }
}