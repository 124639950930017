import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Product from './Product';

export default class InfoCartAdd extends Component {
    render() {

        return (
            <div className="infoCartAdd">
                <div className="infoCartAdd__container">
                    <span className="infoCartAdd__close" onClick={() => { this.props.cart.setNoAddedProduct() }}><b>X</b></span>
                    {/*<Product product={this.props.cart.addedProduct} />*/}

                    <h2>Dodano do koszyka</h2>
                    {
                        this.props.product.image === null ?
                            null
                            :
                            <img src={this.props.cart.addedProduct.image} alt="Brak zdjęcia"></img>
                    }

                    <p>{this.props.cart.addedProduct.name}</p>

                    <NavLink exact to="/koszyk" onClick={() => { this.props.cart.setNoAddedProduct() }}><div className="infoCartAdd_goCart">Przejdź do koszyka</div></NavLink>
                    <div className="infoCartAdd_goCart" onClick={() => { this.props.cart.setNoAddedProduct() }}>Powrót do sklepu</div>
                </div>
            </div>
        )
    }
}