import React, { Component } from 'react';
import APIController from '../APIController';
import PayU from '../PayU';
import { NavLink } from 'react-router-dom';
import InfoLoad from './InfoLoad';
import MessageBoxQuestion from './base/MessageBoxQuestion';
import BaseComponent from './base/BaseComponent';

export default class AdminPanel extends BaseComponent {
    state = {
        orders: [],
        showAll: true,
        loading: true
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    loadData = async () => {
        const response = await this.apiController.get('api/order');
        if (response.ok) {
            const data = await response.json();
            this.setState({ orders: data })
        }
        else {
            this.props.history.push('/');
        }
    }

    render() {
        if (this.state.loading)
            return null;

        return (
            <div className="adminpanel">
                {this.baseRender()}
                <h1>PANEL ADMINISTRATORA</h1>
                {this.renderAdminPanelMenu()}           
            </div>
        )
    }

    renderAdminPanelMenu() {
        return (
            <div className='adminpanel__menu'>
                <NavLink exact to="/panel/zamowienia"><div className="__btn">Zamówienia</div></NavLink>
                <NavLink exact to="/panel/produkty"><div className="__btn">Produkty</div></NavLink>
                <NavLink exact to="/panel/kategorie"><div className="__btn">Kategorie</div></NavLink>
                <NavLink exact to="/panel/metody-wysylki"><div className="__btn">Metody wysyłki</div></NavLink>
                <NavLink exact to="/panel/uzytkownicy"><div className="__btn">Użytkownicy</div></NavLink>
                <NavLink exact to="/panel/kody-rabatowe"><div className="__btn">Kody rabatowe</div></NavLink>
                <NavLink exact to="/panel/statystyki"><div className="__btn">Statystyki</div></NavLink>
                <NavLink exact to="/panel/konfiguracja"><div className="__btn">Konfiguracja</div></NavLink>
                <button onClick={() => { this.payUTest() }}>Test PayU</button>
                <button onClick={() => { this.przelewy24Test() }}>Test Przelewy24</button>
                <NavLink exact to="/"><div className="__btn">Powrót do sklepu</div></NavLink>
            </div>
        )
    }

    logout() {
        this.context.logout();
        this.props.history.push('/login');
    }

    async changeOrderStatus(order) {

        order.status = "wysłane";

        const apiController = new APIController();
        await apiController.changeOrder(order, this.context.getToken());
        this.setState({
            orders: this.state.orders
        })
    }

    toggleShowAll() {
        this.setState({
            showAll: !this.state.showAll
        })
    }

    async deleteOrder(id) {
        if (window.confirm("Czy na pewno usunąć zamówienie?")) {
            const apiController = new APIController(this.context.getToken());
            const response = await apiController.delete('api/order/' + id);

            if (response.ok) {
                //usunięcie zamówienia z listy
                const orders = this.state.orders.filter(o => o.orderId !== id);
                this.setState({
                    orders: orders
                })
            }       
        }
    }

    saveOrdersToFile() {
        const content = JSON.stringify(this.state.orders);
        const contentType = 'text/plain';
        const filename = 'zamowienia_' + (new Date()).toISOString().slice(0, 10) + '.json';
        Downloader.saveToFile(content, filename, contentType);
    }

    async payUTest() {

        const orderId = "52";
        const buyer = {
            email: "imper2222@gmail.com",
                phone: "123456789",
                    firstName: "Jan",
                        lastName: "Kowalski"
        }
        const products = [{
            name: "Wino 1",
            unitPrice: "5000",
            quantity: "1"
        },
        {
            name: "Wino 2",
            unitPrice: "6000",
            quantity: "2"
        }]

        const payU = new PayU();
        const redirectUri = await payU.pay(this.context.getToken(), orderId, buyer, products);
        window.location.href = redirectUri;
    }

    async przelewy24Test() {
        const sessionId = (Math.round(Math.random() * 10000)).toString(); //test123
        const amount = 1;
        const email = "imper2222@gmail.com";
        const cart = [
            {
                sellerId: "test",
                sellerCategory: "test",
                name: "",
                description: "",
                quantity: "",
                price: "",
                number: ""
            }

        ]


        const payU = new PayU();
        const redirectUriToken = await payU.payPrzelewy24(this.context.getToken(), sessionId, amount, null, null, 2, email, null, null, 'PL', null);
        const redirectUri = 'https://sandbox.przelewy24.pl/trnRequest/' + redirectUriToken;
        window.location.href = redirectUri;
    }
}

class Downloader {
    static saveToFile(content, filename, contentType) {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();
        URL.revokeObjectURL(a.href);
    }
}