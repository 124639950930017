import React from 'react';
import DetailsComponent from './base/DetailsComponent';

export default class AdminPanelCategory extends DetailsComponent {
    urlApi = '/api/order'
    urlBack = '/panel/zamowienia'
    mapping = {
        orderId: 'Nr zamówienia',
        cost: "Koszt"
    };

    mappingTypes = {
        orderId: 'label',
        cost: 'label',

    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    renderInputs() {

        if (!this.state.entity.makeOrderUserDto) return null;

        return (
            <div className="details__inputs adminpanel-orders-details">
                <div>
                    <p>Nr zamówienia: {this.state.entity['orderId']}</p>
                    <p>Data: {this.state.entity['date'].slice(0, 10)}</p>
                    <p>Status:&nbsp;
                        <select value={this.state.entity['status']} onChange={(event) => this.changeStatus(event, this.state.entity)}>
                            <option>w realizacji</option>
                            <option>wysłano</option>
                            <option>anulowano</option>
                        </select>
                    </p>

                    

                    <p>Uwagi: {this.state.entity['comments']}</p>
                </div>
                <br />
                <div>
                    <p>PŁATNOŚĆ</p>
                    <p>Koszt: {this.state.entity['cost']} zł</p>
                    <p>Kod promocyjny: {this.state.entity['promoCode']}</p>
                    <p>Metoda płatności: {this.state.entity['paymentMethodName']}</p>
                    <p>Opłacone: <button onClick={() => { }}><i class="fa fa-refresh" aria-hidden="true"></i></button></p>
                </div>
                <br />
                <div>
                    <p>DANE DO WYSYŁSKI</p>
                    <p>Sposób wysyłki: {this.state.entity.shippingMethodName}</p>
                    <p>Koszt wysyłki: {this.state.entity['shippingMethodCost']} zł</p>
                    <p>Imię: {this.state.entity.makeOrderUserDto['firstName']}</p>
                    <p>Nazwisko: {this.state.entity.makeOrderUserDto['lastName']}</p>
                    <p>Telefon: {this.state.entity.makeOrderUserDto['phoneNumber']}</p>
                    <p>Email: {this.state.entity.makeOrderUserDto['email']}</p>
                    <p>Miejscowość: {this.state.entity.makeOrderUserDto['city']}</p>
                    <p>Ulica: {this.state.entity.makeOrderUserDto['street']}</p>
                    <p>Numer domu: {this.state.entity.makeOrderUserDto['houseNumber']}</p>
                    <p>Numer lokalu: {this.state.entity.makeOrderUserDto['localNumber']}</p>
                    <p>Kod pocztowy: {this.state.entity.makeOrderUserDto['postCode']}</p>
                    <p>Poczta: {this.state.entity.makeOrderUserDto['post']}</p>
                    <p>Stasus wysyłki: </p>
                    <p>Firma przewozowa: </p>
                    <p>Numer przesyłki: </p>
                </div>
                <br />
                <div>
                    <p>ZAKUPIONE ARTYKUŁY</p>
                    <table>
                        {
                            this.state.entity.makeOrderItemDtos.map((orderItem) =>
                                <tr>
                                    <td>{orderItem.count}</td>
                                    <td>{orderItem.name}</td>
                                </tr>
                            )
                        }
                    </table>
                </div>



            </div>
        )
    }


    renderButtons() {
        return (
            <div className="details__buttons">
                <button onClick={() => this.back()}>Powrót</button>
            </div>
        )
    }

    async changeStatus(event) {
        const status = event.target.value;
        const newEntity = this.state.entity;
        newEntity.status = status;
        const response = await this.apiController.put(this.urlApi, newEntity);
        if (response.ok) {
            this.setState({
                entity: newEntity
            })
        }
    }
}