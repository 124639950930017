import React, { Component } from 'react';
import { AreaChart, Area, LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import BaseComponent from './base/BaseComponent'
import AdminPanelStatisticsEntries from './AdminPanelStatisticsEntries'

export default class AdminPanelStatistics extends BaseComponent {

    urlBack = '/panel';
    urlApi = '/api/entry';

    state = {
        uniqEntriesPerDay: [],
        ordersPerDay: []
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    loadData = async () => {
        const response = await this.apiController.get(this.urlApi + '/uniqEntriesPerDay');
        if (response.ok) {
            const data = await response.json();
            this.setState({
                uniqEntriesPerDay: data
            })
        }
        else {
            this.props.history.push('/');
        }

        const response2 = await this.apiController.get(this.urlApi + '/ordersPerDay');
        if (response2.ok) {
            const data = await response2.json();
            this.setState({
                ordersPerDay: data
            })
        }
        else {
            this.props.history.push('/');
        }

    }

    render() {
        return (
            <div className="adminpanel__statistics">
                {this.baseRender()}
                <h2>Odwiedziny strony</h2>
                <AdminPanelStatisticsEntries />
                <hr />
                <h2>Liczba unikalnych wejść</h2>
                {this.renderUniqEntriesPerDayChart()}
                <h2>Liczba zamówień</h2>
                {this.renderOrdersPerDayChart()}
                <button onClick={() => this.back()}>Powrót</button>
            </div>
        )
    }

    renderUniqEntriesPerDayChart() {
        const data = this.state.uniqEntriesPerDay.map((d) => { return { date: d.date.slice(0, 10), odwiedziny: d.count } });

        return (
            <div className="adminpanel__statistics__chart" style={{ width: "99%", height: "300px" }}>
                <ResponsiveContainer>
                <AreaChart width={800} height={300} data={data} stroke="#8B6D1E" fill="#8B6D1E">
                    <Area type="monotone" dataKey="odwiedziny" stroke="#8B6D1E" fill="#8B6D1E" activeDot={{ r: 3 }} />
                    <CartesianGrid stroke="ffffff" strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />

                </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }

    renderOrdersPerDayChart() {
        const data = this.state.ordersPerDay.map((d) => { return { date: d.date.slice(0, 10), zamowienia: d.count } });

        return (
            <div className="adminpanel__statistics__chart" style={{ width: "99%", height:"300px" }}>
                <ResponsiveContainer>
                    <AreaChart width={800} height={200} data={data} stroke="#8B6D1E" fill="#8B6D1E">
                    <Area type="monotone" dataKey="zamowienia" stroke="#8B6D1E" fill="#8B6D1E" activeDot={{ r: 3 }} />
                    <CartesianGrid stroke="ffffff" strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />

                </AreaChart>
                </ResponsiveContainer>
            </div>
        )
    }
}