export default class FacebookPixel {
    currency = "PLN";

    viewContent(name) {
        window.fbq('track', 'ViewContent', { content_name: name });
    }
    addToCart(productName) {
        //window.fbq('track', 'PageView');
        window.fbq('track', 'AddToCart', { content_name: productName });
    }
    purchase(productName, count, cost) {
        window.fbq('track', 'Purchase', { content_name: productName, num_items: count, value: cost, currency: this.currency });
    }
}