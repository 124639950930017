import React, { Component } from 'react';

export default class InfoAge extends Component {
    render() {

        return(
        <div className="age-alert">
            <img src="img/logo1.png" />
            <h1>Czy ukończyłeś 18 lat?</h1>
            <div>
                <div className="age-alert-button" onClick={() => {this.props.toggle() }}>
                    <h2>TAK</h2>
                </div>
                    <div className="age-alert-button" onClick={() => { window.location.assign('http://google.com'); }}>
                    <h2>NIE</h2>
                </div>
            </div>
        </div>
        )
    }
}