import APIController from "./APIController";
import FacebookPixel from "./FacebookPixel";

export default class CartModel
{
    constructor(refresh)
    {
        this.products = [];
        this.shippingMethod = {};
        this.addedProduct = null;
        this.promoCode = null;
        this.refresh = refresh;
        this.loadProductsFromStorage();
        this.evaluateProducts();
    }

    count()
    {
        let count = 0;
        for (let product of this.products)
        {
            count = count + product.count;
        }
        return count;
    }

    evaluateProducts() {
        for (let product of this.products)
            this.evaluateProduct(product);         
    }

    evaluateProduct(product) {
        product.baseValue = product.price * product.count;
        product.value = product.baseValue;
        if (this.promoCode != null) {
            if (this.promoCode.categories != null && this.promoCode.categories.length > 0) {
                if (this.promoCode.categories.includes(product.categoryId)) {
                    product.promoValue = product.baseValue * (1 - this.promoCode.percentDiscount / 100);
                    product.value = product.promoValue;
                }
            }
            else {
                product.promoValue = product.baseValue * (1 - this.promoCode.percentDiscount / 100);
                product.value = product.promoValue;
            }
                
        }       
    }

    productsValue()
    {
        let value = 0;
        for (let product of this.products)
            value = value + product.value;                
        return value;
    }
    freeShipping() {
        return this.productsBaseValue() >= this.shippingMethod.freeCostTreshold;
    }
    deliveryValue()
    {
        if (this.freeShipping())
            return 0;
        else if (this.shippingMethod.cost === undefined)
            return 0;
        else
            return this.shippingMethod.cost;
    }
    toFreeDeliveryValue() {
        if (this.freeShipping())
            return 0;
        else
            return this.shippingMethod.freeCostTreshold - this.productsBaseValue();

    }
    cartValue()
    {
        return this.productsValue() + this.deliveryValue()
    }
    productsBaseValue()
    {
        let value = 0;
        for (let product of this.products)
            value = value + product.baseValue;
        return value;
    }

    addProduct(product, count)
    {
        const pixel = new FacebookPixel();
        pixel.addToCart(product.name);

        const productInCart = this.products.find(p => p.id === product.id);
        if (productInCart === undefined)
        {
            const productToCart = {
                id: product.id,
                name: product.name,
                price: product.price,
                categoryId: product.categoryId,
                count: count
            }
            this.products.push(productToCart);
        }
        else
        {
            productInCart.count = productInCart.count + count;
        }

        this.addedProduct = product;

        this.evaluateProducts();
        this.saveProductsToStorage();
        this.refresh();

        const entry = {
            page: 'Dodano ' + product.name + ' do koszyka'
        }

        const apiController = new APIController();
        apiController.post('api/entry', entry);
    }
    removeProduct(productId)
    {
        const productInCart = this.products.find(p => p.id === productId);
        if (productInCart !== undefined)
        {
            const index = this.products.indexOf(productInCart);
            this.products.splice(index, 1);
        }
        this.evaluateProducts();
        this.saveProductsToStorage();
        this.refresh();
    }
    increaseProduct(productId)
    {
        const productInCart = this.products.find(p => p.id === productId);
        productInCart.count = productInCart.count + 1;
        this.evaluateProducts();
        this.saveProductsToStorage();
        this.refresh();
    }
    decreaseProduct(productId)
    {
        const productInCart = this.products.find(p => p.id === productId);
        productInCart.count = productInCart.count - 1;
        if (productInCart.count <= 0)
        {
            this.removeProduct(productId);
        }
        this.evaluateProducts();
        this.saveProductsToStorage();
        this.refresh();
    }
    empty()
    {
        this.products = [];
        this.promoCode = null;
        this.evaluateProducts();
        this.saveProductsToStorage();
        this.refresh()
    }
    setNoAddedProduct() {
        this.addedProduct = null;
        this.evaluateProducts();
        this.refresh();
    }
    setPromoCode(promoCode) {
        this.promoCode = promoCode;
        this.evaluateProducts();
        this.refresh();
    }
    saveProductsToStorage()
    {
        const cart = {
            products: this.products// productsWithoutImage
        }
    localStorage.removeItem('cart');
        localStorage.setItem('cart', JSON.stringify(cart));
    }
    loadProductsFromStorage() {
    var cart = localStorage.getItem("cart");
    if (cart != undefined)
    {
        cart = JSON.parse(cart);
        this.products = cart.products;
    }
}


}