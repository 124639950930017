import React, { Component } from 'react';
import BaseComponent from './base/BaseComponent';
import EmailValidator from '../helpers/EmailValidator'

export default class Register extends BaseComponent {
    state = {
        email: "",
        password: "",
        secondPassword: "",
        validateErrorMessage: ""
    }

    render() {
        return (
            <div className="register">
                <form>
                    <label>Email</label><input type="text" name="email" value={this.state.email} onChange={(e) => { this.handleInputChange(e) }}></input>
                    <label>Hasło</label><input type="password" name="password" value={this.state.password} onChange={(e) => { this.handleInputChange(e) }}></input>
                    <label>Powtórz hasło</label><input type="password" name="secondPassword" value={this.state.secondPassword} onChange={(e) => { this.handleInputChange(e) }}></input>
                </form>
                <button onClick={() => this.register()}>Zarejestruj</button>
            </div>
        )
    }

    async register() {
        if (this.validate()) {
            const register = {
                email: this.state.email,
                password: this.state.password
            }
            
            const response = await this.apiController.post('api/user/register', register);
            if (response.ok) {
                console.log('zarejestrowano')
                const data = await response.json();
                this.context.setToken(data.token);
                this.props.history.push('/');
            }
        }
    }

    validate() {
        if (!EmailValidator.isEmailValid(this.state.email))
            return false;
        if (this.state.password == '')
            return false;
        if (this.state.secondPassword == '')
            return false;
        if (this.state.password !== this.state.secondPassword)
            return false;
        return true;
    }
}