import React, { Component } from 'react';
import APIController from '../APIController'
import { UserContext } from '../UserContex'
import DetailsComponent from './base/DetailsComponent';

export default class AdminPanelPromoCode extends DetailsComponent {

    urlApi = '/api/promoCode'
    urlBack = '/panel/kody-rabatowe'
    mapping = {
        code: 'Kod',
        percentDiscount: 'Rabat [%]',
        minOrderValue: 'Minimalny zakup',
        dateExpired: 'Data ważności',
        user: 'Użytkownik',
        valid: 'Ważny',
        multiply: 'Wielokrotny',
        categories: 'Kategorie'
    };

    mappingTypes = {
        code: 'text',
        percentDiscount: 'number',
        minOrderValue: 'number',
        dateExpired: 'date',
        user: 'selection',
        valid: 'checkbox',
        multiply: 'checkbox',
        categories: 'select-multiple'
    }

    mappingDictionaries = {
        user: 'userId',
        categories: 'categoryId'
    }

    mappingDictionariesDisplay = {
        user: 'email',
        categories: 'name'
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    setDefaults = () => {
        const entity = { valid: true }
        this.setState({
            entity: entity
        })
    }

    loadDictionaries = async () => {
        const response = await this.apiController.get('/api/user');
        if (response.ok) {
            const user = await response.json();
            this.setState({
                user: user
            })
        }
        const response2 = await this.apiController.get('/api/category');
        if (response2.ok) {
            const categories = await response2.json();
            this.setState({
                categories: categories
            })
        }

    }
}
