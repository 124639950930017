import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Footer extends Component {
    render() {

        return (
            <footer id="footer-section" className="footer-section">
                <div className="footer-container">
                    <div className="footer-contact">
                        <h4>Kontakt</h4>
                        <div>
                            <p>
                                <i className="fa fa-home" aria-hidden="true"></i> WINNICA<br /> Makowice
                                <br /> 58-100 Świdnica
                            </p>
                            <p>
                                <i className="fa fa-home" aria-hidden="true"></i> WINIARNIA/SKLEP
                                <br /> ul. Kraszowicka 23<br /> 58-100 Świdnica<br />
                            </p>
                        </div>
                        <div>
                            <p><i className="fa fa-phone" aria-hidden="true"></i> 662 043 709</p>
                            <p><i className="fa fa-phone" aria-hidden="true"></i> 604 901 954</p>
                            <p><i className="fa fa-envelope" aria-hidden="true"></i> kontakt@winnicaswidnicka.eu</p>
                        </div>
                    </div>
                    <div className="footer-social-media">
                        <h4>Społeczność</h4>
                        <p>
                            <a href="https://www.facebook.com/WinnicaSwidnicka/"><i className="fa fa-facebook-square" aria-hidden="true"> Facebook</i></a>
                        </p>
                        <p>
                            <a href="https://www.instagram.com/winnica.swidnicka/"><i className="fa fa-instagram" aria-hidden="true"> Instagram</i></a>
                        </p>
                        <p>
                            <a href="https://www.youtube.com/channel/UCoaQ34d2d4blq1Dh1D_-9Vg"><i className="fa fa-youtube-play" aria-hidden="true"> Youtube</i></a>
                        </p>
                        <p>
                            <a href="https://www.tripadvisor.com/Attraction_Review-g21212705-d21209245-Reviews-Winnica_Swidnicka-Makowice_Lower_Silesia_Province_Southern_Poland.html"><i className="fa fa-tripadvisor" aria-hidden="true"> Tripadvisor</i></a>
                        </p>
                        <p>
                            <a href="https://www.google.com/search?q=winnica%20%C5%9Bwidnicka&rlz=1C1MSIM_enPL732PL732&oq=winnica+%C5%9Bwidnicka&aqs=chrome.0.69i59j35i39j0l2j0i22i30l2j69i60l2.5199j1j7&sourceid=chrome&ie=UTF-8&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALeKk00dSGtZFHJfKlzdzWbQvb2jlOPSOA:1610445592686&rflfq=1&num=10&rldimm=2789146404540854288&lqi=ChJ3aW5uaWNhIMWbd2lkbmlja2FaKAoSd2lubmljYSDFm3dpZG5pY2thIhJ3aW5uaWNhIMWbd2lkbmlja2E&ved=2ahUKEwiIqO2rkZbuAhWBHOwKHY_xDykQvS4wBHoECAMQHA&rlst=f#rlfi=hd:;si:2789146404540854288,l,ChJ3aW5uaWNhIMWbd2lkbmlja2FaKAoSd2lubmljYSDFm3dpZG5pY2thIhJ3aW5uaWNhIMWbd2lkbmlja2E;mv:[[50.85273435110847,16.616272818143937],[50.76856143139755,16.35878075271425],null,[50.81066685091199,16.487526785429093],13]"><i className="fa fa-google" aria-hidden="true"> Google</i></a>
                        </p>


                    </div>
                    <div className="footer-social-media">
                        <h4>Informacje</h4>
                        <p>
                            <NavLink exact to='/regulamin'>Regulamin</NavLink>
                        </p>
                        <p>
                            <NavLink exact to='/polityka-prywatnosci'>Polityka prytwaności</NavLink>
                        </p>
                    </div>
                    {/*
                    <div class="footer-information">
                        <h4>Infomracje</h4>
                        <ul>
                            <li><a href="winnica.html">O winnicy</a></li>
                            <li><a href="wina.html">Nasze wina</a></li>
                            <li><a href="oferta.html">Oferta</a></li>
                            <li><a href="kontakt.html">Kontakt</a></li>
                        </ul>
                    </div>*/}
                    <div className="footer-logo">
                        <img src="img/logo_full.png" />
                        <div> Wszelkie prawa zastrzeżone <br />&copy; <a href="http:\\www.oskargut.pl">Oskar Gut</a></div>
                    </div>
                </div>
            </footer>
        )
    }
}