import GridComponent from './base/GridComponent';

export default class AdminPanelPromoCodes extends GridComponent {

    className = 'adminpanel-promocodes';
    mapping = {
        code: 'Kod'
    };
    urlApi = 'api/promoCode';
    urlDetails = '/panel/kod-rabatowy';
    urlBack = '/panel';

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    loadData = async () => {
        const response = await this.apiController.get(this.urlApi);
        if (response.ok) {
            const entities = await response.json();
            this.setState({
                entities: entities
            })
        }
    }
}
