import DetailsComponent from './base/DetailsComponent';

export default class AdminPanelProduct extends DetailsComponent {
    title =  'Dane produktu'
    urlApi = '/api/product'
    urlBack = '/panel/produkty'
    mapping = {       
        name: 'Nazwa',
        price: 'Koszt',
        category: 'Kategoria',
        available: 'Dostępny',
        highlighted: 'Wyróżniony',
        outDated: 'Nieaktualny',
        description: 'Opis',
        image: 'Obraz'
    };

    mappingTypes = {     
        name: 'text',
        price: 'number',
        category: 'selection',
        available: 'checkbox',
        highlighted: 'checkbox',
        outDated: 'checkbox',
        description: 'textarea',
        image: 'image'
    }

    mappingDictionaries = {
        category: 'categoryId'
    }

    mappingDictionariesDisplay = {
        category: 'name'
    }

    mappingValidate = {
        name: (v) => { return v ? true : false },
        price: (v) => { if (v > 0) return true; else return false }
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    loadDictionaries = async () => {
        const response = await this.apiController.get('/api/category');
        if (response.ok) {
            const category = await response.json();
            this.setState({
                category: category
            })
        }
    }
}