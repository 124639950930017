import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class MessageBoxQuestion extends Component {
    render() {

        return (
            <div className="messagebox">
                <div>
                    <h2>{this.props.question}</h2>
                    <button onClick={() => this.handleYes()}>Tak</button>
                    <button onClick={() => this.handleNo()}>Nie</button>
                </div>
            </div>
        )
    }

    handleYes() {
        if (this.props.yes)
            this.props.yes();
        this.props.close();
    }

    handleNo() {
        if (this.props.no)
            this.props.no();
        this.props.close();
    }
}