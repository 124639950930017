import React, { Component } from 'react';
import APIController from '../../APIController'
import EntryController from '../../helpers/EntryController'
import { UserContext } from '../../UserContex'
import InfoLoad from '../InfoLoad';


export default class BaseComponent extends Component {

    state = {
        entities: []
    }

    //mapowanie, które pola mają być wyświetlana i z jaką etykietą
    mapping = {}

    className = '';
    //nagłówek okna
    title = null;
    //uchwyt na metodę rejestrujące wejścia
    registrationEntry = () => { return null }
    loadMessage = 'Wczytuję...';
    apiController = new APIController(this.context.getToken());
    entryController = new EntryController();
    urlBack = '';

    //uchwyt na wszystkie metody pobierające dane
    loadData = () => { };
    //uchwyt na metody pobierające słowniki
    loadDictionaries = async () => { }

    //uchwyt na spradzanie uprawnień do okna
    authorize = () => { return true };

    //renderowanie okna wczytywania
    infoLoad() {
        if (this.state.loading)
            return <InfoLoad message={this.loadMessage} />
    }

    constructor(props, context) {
        super(props, context);
    }

    async registerEntry() {
        await this.entryController.registerEntry(this.registrationEntry())
    }

    async componentDidMount() {
        this.setState({ loading: true });
        if (this.authorize()) {
            await this.loadData();
            if (this.registrationEntry())
                this.registerEntry();
        }
        this.setState({ loading: false });
        window.scrollTo(0, 0);
    }

    baseRender() {
        return (
            <div className={this.className}>
                {this.infoLoad()}
            </div>
            )
    }

    renderTitle() {
        if (!this.title)
            return null;

        return (
            <h1>{this.title}</h1>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const type = target.type;
        const name = target.name;
        let value;
        switch (type) {
            case "text":
            case "textarea":
            case "email":
            case "password":
                value = target.value; break;
            case "number": value = parseFloat(target.value); break;
            case "date": value = target.value; break;
            case "checkbox": value = target.checked ? true : false; break;
            case "select-one": value = parseFloat(target.value); break;
            case "select-multiple": value = Array.from(event.target.selectedOptions, option => parseInt(option.value)); break;
            case "file":
                let reader = new FileReader();
                reader.readAsDataURL(target.files[0]);
                reader.onload = async () => {
                    value = reader.result;
                    var objectPath = require("object-path");
                    this.setState(prevState => {
                        const newState = Object.assign({}, prevState);
                        objectPath.set(newState, name, value);
                        return newState;
                    });
                }; return;
        }

        var objectPath = require("object-path");
        this.setState(prevState => {
            const newState = Object.assign({}, prevState);
            objectPath.set(newState, name, value);
            return newState;
        });
    }

    back() {
        this.props.history.push(this.urlBack);
    }

    /////////////////////////////////Metody nie dotyczące wprost okienka
    addParametersToUrl(url, parameters, values) {
        if (parameters.length > 0) {
            url = url + '?';
            for (let i = 0; i < parameters.length; i++) {
                url = url + parameters[i] + "=" + values[i] + "&&";
            }
            url = url.substring(0, url.length - 2);
        }
        return url;
    }


}
BaseComponent.contextType = UserContext;