import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import CartForm from './CartForm';
import Header from './Header';
import CartModel from '../CartModel';
import APIController from '../APIController'
import BaseComponent from './base/BaseComponent';


export default class Cart extends BaseComponent {
    state = {
        shippingMethods: [],
        selectedShippingMethodId: {},
        promoCode: this.props.cart.promoCode ? this.props.cart.promoCode.code : '',
        promoCodeValidationError: null
    }

    registrationEntry = () => { return 'Koszyk' }
    loadData = async () => {
        await this.loadShippingMethods();
    }

    render() {
        console.log(this.props.cart.promoCode);

        return (
            <div className="cart">
                <h1>TWÓJ KOSZYK</h1>
                <div className="cart__container">
                    {this.renderCartProducts()}
                    <div className="cart__info">
                        {this.renderCartSummary()}
                        <br />
                        <CartForm cart={this.props.cart} />
                    </div>
                </div>
            </div>
        );
    }

    renderCartProducts() {
        return(
        <section className="cart__products">
            <ul>
                {
                    this.props.cart.products == null || this.props.cart.products.length === 0 ?
                        <h2>Brak produktów w koszyku</h2>
                        :
                        this.props.cart.products.map((product) =>
                            <li className="cart__product" key={product.id}>
                                <h3>{product.name}</h3>
                                <div className="cart__product__count">
                                    <div onClick={() => { this.props.cart.decreaseProduct(product.id) }}><i className="fa fa-minus-circle" aria-hidden="true"></i></div>
                                    {product.count}
                                    <div onClick={() => { this.props.cart.increaseProduct(product.id) }}><i className="fa fa-plus-circle" aria-hidden="true"></i></div>
                                </div>
                                <div className="cart__product__price">
                                    {product.price * product.count} zł
                                            </div>
                                <div onClick={() => { this.props.cart.removeProduct(product.id) }}><i className="fa fa-trash" aria-hidden="true"></i></div>
                            </li>
                        )
                }
            </ul>

            <div className="cart__products__buttons">
                <div className="__btn" onClick={() => { this.props.cart.empty() }}>Opróżnij koszyk</div>
                <NavLink exact to="/"><div className="__btn">Powrót do sklepu</div></NavLink>
            </div>

        </section>)
    }

    renderCartSummary() {
        return (
            <section className="cart__summary">
                <h2>PODSUMOWANIE</h2>
                <hr />
                <div className="cart__summary__container">
                    {this.renderShippingMethod()}
                    <hr />
                    {this.renderPromoCode()}
                    <hr />
                    {this.renderCostSummary()}          
                    <hr />
                    {this.renderPaymentMethod()}

                </div>
            </section>
            )
    }

    renderCostSummary() {
        return (
            <div>
                <p>Wartość zamówienia: {this.props.cart.promoCode && <s>{this.props.cart.productsBaseValue()} zł </s>} <b>{this.props.cart.productsValue().toFixed(2)} zł</b></p>
                <p>Koszt przesyłki: <b>{this.props.cart.deliveryValue().toFixed(2)} zł</b></p>
                {this.props.cart.toFreeDeliveryValue() > 0 && <p> Do darmowej wysyłki brakuje: <b>{this.props.cart.toFreeDeliveryValue().toFixed(2)} zł</b></p>}
                <hr />
                <h3>Do zapłaty: {this.props.cart.cartValue().toFixed(2)} zł</h3>
            </div>
        )
    }

    renderShippingMethod() {
        return (
            <div>
                <h5>Metoda wysyłki</h5>
                <label><i className="fa fa-paper-plane" aria-hidden="true"></i> </label>
                <select className="cart__summary__delivery" value={this.state.selectedShippingMethodId} onChange={this.shippingMethodChangeHandler.bind(this)} onClick={() => this.shippingMethodClickHandler()} name="shippingMethods" id="shippingMethods">
                    {
                        this.state.shippingMethods.map((shippingMethod) =>
                            <option key={shippingMethod.shippingMethodId} value={shippingMethod.shippingMethodId}>{shippingMethod.name + ' (' + shippingMethod.cost + ' zł)'}</option>
                        )
                    }
                </select>
            </div>
        )
    }

    renderPaymentMethod() {
        return (
            <div>
                <h5>Metoda płatności</h5>
                <label><i className="fa fa-money" aria-hidden="true"></i> </label>
                <select value="przelew tradycyjny" onChange={() => { }} onClick={() => this.paymentMethodClickHandler()}>
                    <option value="przelew tradycyjny">przelew tradycyjny</option>
                </select>
                <p>Prosimy o dokonanie wpłaty na nasze konto bankowe. Numer konta oraz tytuł przelewu otrzymają Państwo w wiadomości potwierdzającej zamówienie</p>
            </div>
        )
    }

    renderPromoCode() {
        return (
            <div className="promo-code">
                <h5>Kod rabatowy</h5>
                <label><i class="fa fa-percent" aria-hidden="true"></i></label>
                <input type="text" name="promoCode" value={this.state.promoCode} onChange={(e) => this.handleInputChange(e)} />
                <button onClick={() => this.submitPromoCode()}>Zastosuj</button>
                {this.state.promoCodeValidationError && <p className="warning-message"><b>{this.state.promoCodeValidationError}</b></p>}
            </div>
            )
    }

    shippingMethodChangeHandler(e) {
        const shippingMethod = this.state.shippingMethods.find(sm => sm.shippingMethodId == e.target.value);

        const cart = this.props.cart;
        cart.shippingMethod = shippingMethod;

        this.setState({
            selectedShippingMethodId: e.target.value,
        })
    }

    shippingMethodClickHandler() {
        const entry = {
            page: 'Kliknięto wybór sposobu wysyłki'
        }

        const apiController = new APIController();
        apiController.post('api/entry', entry);
    }

    paymentMethodClickHandler() {
        const entry = {
            page: 'Kliknięto wybór sposobu płatności'
        }

        const apiController = new APIController();
        apiController.post('api/entry', entry);
    }

    async loadShippingMethods() {
        const shippingMethods = await this.apiController.getShippingMethods();

        const cart = this.props.cart;
        cart.shippingMethod = shippingMethods[0];

        this.setState({ shippingMethods: shippingMethods, selectedShippingMethodId: shippingMethods[0].shippingMethodId})
        
    }

    async submitPromoCode() {
        const promoCode = this.state.promoCode;
        const response = await this.apiController.get('api/promoCode/check/' + promoCode);
        if (response.ok) {
            const response2 = await this.apiController.get('api/promoCode/' + promoCode);
            if (response.ok) {
                const code = await response2.json();
                this.props.cart.setPromoCode(code);
                this.setState({
                    promoCodeValidationError: 'Aktywny kupon rabatowy'
                })
            }

        }
        else if (response.status == 400) {
            const errors = await response.json();
            this.setState({
                promoCodeValidationError: errors[Object.keys(errors)[0]].toString()
            })
        }

    }
}