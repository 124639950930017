import React, { Component } from 'react';

export default class InfoOrder extends Component {
    render() {

        return (
            <div className="infoLoad">
                <div className="infoLoad__container">     
                    <img src="img/logo_full.png" />
                    <p>Trwa wczytywanie...</p>
                    <div class="loader"></div>
                </div>
            </div>
        )
    }
}