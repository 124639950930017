import React, { Component } from 'react';
import { Route, NavLink, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Login from './components/Login';
import Main from './components/Main';
import Cart from './components/Cart';
import AdminPanel from './components/AdminPanel';
import Footer from './components/Footer';
import InfoAge from './components/InfoAge';
import CartModel from './CartModel';
import ProductDetails from './components/ProductDetails';
import Newsletter from './components/Newsletter'
import Regulations from './components/Regulations';
import PrivacyPolicy from './components/PrivacyPolicy';
import AdminPanelOrders from './components/AdminPanelOrders';
import AdminPanelOrder from './components/AdminPanelOrder';
import AdminPanelProducts from './components/AdminPanelProducts';
import AdminPanelProduct from './components/AdminPanelProduct';
import AdminPanelShippingMethods from './components/AdminPanelShippingMethods';
import AdminPanelShippingMethod from './components/AdminPanelShippingMethod';
import AdminPanelPromoCodes from './components/AdminPanelPromoCodes';
import AdminPanelPromoCode from './components/AdminPanelPromoCode';
import AdminPanelCategories from './components/AdminPanelCategories';
import AdminPanelCategory from './components/AdminPanelCategory';
import AdminPanelStatistics from './components/AdminPanelStatistics';
import AdminPanelUsers from './components/AdminPanelUsers';
import Register from './components/Register';
import Account from './components/Account';
import AdminPanelConfiguration from './components/AdminPanelConfiguration';
import Header from './components/Header';

export default class App extends Component {
    static displayName = App.name;

    state = {
        cart: new CartModel(() => { this.refreshCart() }),
        showInfoAge: localStorage.getItem('infoAge') != '0'
    }

    refreshCart() {
        const cart = this.state.cart;
        this.setState({
            cart: cart
        })
    }

    render() {

        if (!this.state.cart) return null;
        return (
            <BrowserRouter>
                {this.state.showInfoAge === true && <InfoAge toggle={() => { this.toggleShowInfoAge() }} />}
                <Header cart={this.state.cart} />
                <div className="main-container">
                <Switch>
                    <Route exact path='/' render={(props) => <Main {...props} cart={this.state.cart} />} />
                    <Route exact path='/sklep' component={Main} />
                    <Route exact path='/produkt/:id' render={(props) => <ProductDetails {...props} cart={this.state.cart} />} />
                    <Route exact path='/koszyk' render={(props) => <Cart {...props} cart={this.state.cart} />} />
                    <Route exact path='/newsletter' component={Newsletter} />
                    <Route exact path='/regulamin' component={Regulations} />
                    <Route exact path='/polityka-prywatnosci' component={PrivacyPolicy} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/rejestracja' component={Register} />
                    <Route exact path='/konto' component={Account} />
                    <Route exact path='/panel' component={AdminPanel} />
                    <Route exact path='/panel/konfiguracja' component={AdminPanelConfiguration} />
                    <Route exact path='/panel/statystyki' component={AdminPanelStatistics} />
                    <Route exact path='/panel/uzytkownicy' component={AdminPanelUsers} />
                    <Route exact path='/panel/zamowienia' component={AdminPanelOrders} />
                    <Route exact path='/panel/zamowienie/:id' component={AdminPanelOrder} />
                    <Route exact path='/panel/produkty' component={AdminPanelProducts} />
                    <Route exact path='/panel/produkt/' component={AdminPanelProduct} />
                    <Route exact path='/panel/produkt/:id' component={AdminPanelProduct} />
                    <Route exact path='/panel/kategorie' component={AdminPanelCategories} />
                    <Route exact path='/panel/kategoria' component={AdminPanelCategory} />
                    <Route exact path='/panel/kategoria/:id' component={AdminPanelCategory} />
                    <Route exact path='/panel/metody-wysylki' component={AdminPanelShippingMethods} />
                    <Route exact path='/panel/metoda-wysylki/' component={AdminPanelShippingMethod} />
                    <Route exact path='/panel/metoda-wysylki/:id' component={AdminPanelShippingMethod} />
                    <Route exact path='/panel/kody-rabatowe' component={AdminPanelPromoCodes} />
                    <Route exact path='/panel/kod-rabatowy/' component={AdminPanelPromoCode} />
                    <Route exact path='/panel/kod-rabatowy/:id' component={AdminPanelPromoCode} />
                    </Switch>
                </div>
                <Footer />
            </BrowserRouter >

            /*<Route exact path='/' component={Home} />
            <Route path='/counter' component={Counter} />
            <Route path='/fetch-data' component={FetchData} />
             */
        );
    }

    toggleShowInfoAge() {
        localStorage.setItem('infoAge',0)
        this.setState({
            showInfoAge: !this.state.showInfoAge
        })
    }

}
