import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class InfoOrder extends Component {
    render() {

        return (
            <div className="infoOrder">
                <div className="infoOrder__container">
                    <h1>Zamówienie przyjęte</h1>
                    <p>Na podany adres email została wysłana wiadomość z potwierdzeniem zamówienia. Prosimy o dokonanie przelewu na podany w wiadomości numer konta.</p>
                    <NavLink className="__btn" exact to="/">Powrót do sklepu</NavLink>
                </div>
            </div>
        )
    }
}