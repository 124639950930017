import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import InfoLoad from './InfoLoad';
import InfoOrder from './InfoOrder'
import APIController from '../APIController';
import EmailValidator from '../helpers/EmailValidator'

export default class CartForm extends Component {

    state = {
        firstName: "",
        lastName: "",
        city: "",
        street: "",
        houseNumber: "",
        localNumber: "",
        postCode: "",
        post: "",
        phoneNumber: "",
        email: "",
        comments: "",
        regulation: false,
        newsletter: false,
        firstNameWarning: false,
        lastNameWarning: false,
        cityWarning: false,
        streetWarning: false,
        houseNumberWarning: false,
        postCodeWarning: false,
        postWarning: false,
        phoneWarning: false,
        emailWarning: false,
        regulationWarning: false,
        showPaczkomatChooser: false,
        showedPaczkomatChooser: false,
        paczkomatCode: "",
        orderDone: false,
        orderFailed: null,
        loading: false
    }

    componentDidMount() {
        window.setPoint = (point) => this.setState({ paczkomatCode: point.name, showPaczkomatChooser: false, paczkomatCodeWarning: false });

        const script1 = document.createElement("script");
        script1.src = "https://geowidget.easypack24.net/js/sdk-for-javascript.js";
        script1.async = true;
        document.body.appendChild(script1);

        const script2 = document.createElement("script");
        script2.type = 'text/javascript';
        script2.async = true;
        //script2.innerHTML = "window.easyPackAsyncInit = function () {easyPack.init({display: {showTypesFilters: false,showSearchBar: true}, map: {useGeolocation: true } });var map = easyPack.mapWidget('easypack-map', function (point) {window.setPoint(point)});};";
        script2.innerHTML = "window.easyPackAsyncInit = function () {easyPack.init({display: {showTypesFilters: false,showSearchBar: true}, map: {useGeolocation: true } });window.loadPaczkomat = function() { easyPack.dropdownWidget('easypack-widget', function (point) {window.setPoint(point)});};};";
        //script2.innerHTML = "window.easyPackAsyncInit = function () {easyPack.init({display: {showTypesFilters: false,showSearchBar: true}}); window.openModalPaczkomat = function(){ easyPack.modalMap(function (point,modal) { modal.close(); window.setPoint(point) }, { width: 500, height: 600 });};};";
        document.body.appendChild(script2);
        

    }


    render() {
        return (
            <form className="cart__form">



                {this.state.loading && <InfoLoad message={"Składanie zamówienia..."} />}
                {this.state.orderDone && <InfoOrder email={this.state.email} />}

                <h2>DANE ADRESOWE</h2>
                <hr />
                <div>
                    <label>Imię</label>
                    <input className={this.state.firstNameWarning ? "warning" : ""} type="text" name="fistName" id="firstName" autoComplete="given-name" onChange={this.firstNameChangeHandler.bind(this)} value={this.state.firstName} placeholder={this.state.firstNameWarning ? "Nie podano imienia" : ""}></input>
                </div>
                <div>
                    <label>Nazwisko</label>
                    <input className={this.state.firstNameWarning ? "warning" : ""} type="text" autoComplete="family-name" onChange={this.lastNameChangeHandler.bind(this)} value={this.state.lastName} placeholder={this.state.lastNameWarning ? "Nie podano nazwiska" : ""}></input>
                </div>
                <div>
                    <label>Miejscowość</label>
                    <input className={this.state.cityWarning ? "warning" : ""} type="text" autoComplete="shipping address-level2" onChange={this.cityChangeHandler.bind(this)} value={this.state.city} placeholder={this.state.cityWarning ? "Nie podano miejscowości" : ""}></input>
                </div>
                <div>
                    <label>Ulica</label>
                    <input className={this.state.streetWarning ? "warning" : ""} type="text" autoComplete="shipping address-line1" onChange={this.streetChangeHandler.bind(this)} value={this.state.street} placeholder={this.state.streetWarning ? "Nie podano ulicy" : ""}></input>
                </div>
                <div>
                    <label>Nr domu</label>
                    <input className={this.state.houseNumberWarning ? "warning" : ""} type="text" autoComplete="shipping address-line2" onChange={this.houseNumberChangeHandler.bind(this)} value={this.state.houseNumber} placeholder={this.state.houseNumberWarning ? "Nie podano numeru domu" : ""}></input>
                </div>
                <div>
                    <label>Nr lokalu</label>
                    <input type="text"  onChange={this.localNumberChangeHandler.bind(this)} value={this.state.localNumber}></input>
                </div>
                <div>
                    <label>Kod pocztowy</label>
                    <input className={this.state.postCodeWarning ? "warning" : ""} type="text" autoComplete="shipping postal-code" onChange={this.postCodeChangeHandler.bind(this)} value={this.state.postCode} placeholder={this.state.postCodeWarning ? "Nie podano kodu pocztowego" : ""}></input>
                </div>
                <div>
                    <label>Poczta</label>
                    <input className={this.state.postWarning ? "warning" : ""} type="text" onChange={this.postChangeHandler.bind(this)} value={this.state.post} placeholder={this.state.postWarning ? "Nie podano poczty" : ""}></input>
                </div>
                <div>
                    <label>Nr telefonu</label>
                    <input className={this.state.phoneWarning ? "warning" : ""} type="text" autoComplete="tel" onChange={this.phoneNumberChangeHandler.bind(this)} value={this.state.phoneNumber} placeholder={this.state.phoneWarning ? "Nie podano numeru telefonu" : ""}></input>
                </div>
                <div>
                    <label htmlFor="email">Adres email</label>
                    <input className={this.state.emailWarning ? "warning" : ""} type="email" name="email" id="email" autoComplete="email" onChange={this.emailChangeHandler.bind(this)} value={this.state.email} placeholder={this.state.emailWarning ? "Niepoprawny adres email" : ""} required></input>
                </div>
                <div>
                    <label>Uwagi</label>
                    <textarea onChange={this.commentsChangeHandler.bind(this)} value={this.state.comments}></textarea>
                </div>

                {
                    this.props.cart.shippingMethod != null && this.props.cart.shippingMethod.name === 'Paczkomat' &&
                    <div>

                        <div>
                            <label>Paczkomat</label>
                            <input className={this.state.paczkomatCodeWarning ? "warning" : ""} type="text" value={this.state.paczkomatCode} placeholder="Wybierz paczkomat" onClick={() => this.paczkomatOnClickHandler()} readOnly />
                        </div>
                        <div className='easypack-map' style={this.state.showPaczkomatChooser ? { display: "block" } : { display: "none" }}><div id="easypack-widget"></div></div>
                    </div>
                }
                <hr />
                {this.renderRegulations()}
                {(false === true) && this.renderNewsletter()}
                <hr />
                <div className="cart__form__btn-container">
                    <div className="__btn" onClick={() => { this.makeOrder() }}>Zamów</div>
                </div>
                {this.props.cart.products == null || this.props.cart.products.length == 0 && <p><b>Brak produktów w koszyku</b></p>}
                {this.state.orderFailed && <p><b>{this.state.orderFailed}</b></p>}
            </form>

        );
    }

    renderRegulations() {
        return (
            <div className="cart__form__regulations">
                <label>
                    <input type="checkbox" checked={this.state.regulation} onChange={(e) => this.regulationChangeHandler(e)} />
                        Przeczytałem i akceptuję <NavLink exact to='/regulamin'>regulamin</NavLink>, <NavLink exact to='polityka-prywatnosci'>politykę prywatności</NavLink> oraz oświadczam, że jestem osobą pełnoletnią
                     </label>
                {this.state.regulationWarning == true && <p><b>Zaakceptuj regulamin</b></p>}
            </div>
            )
    }

    renderNewsletter() {
        return (
            <div className="cart__form__regulations">
                <label>
                    <input type="checkbox" checked={this.state.newsletter} onChange={(e) => this.newsletterChangeHandler(e)} />
                Zapisz mój adres email do Newslettera w celu otrzymywaniu promocji i rabatów
            </label>
            </div>
        )
    }

    firstNameChangeHandler(e) {
        this.setState({
            firstName: e.target.value,
            firstNameWarning: this.state.firstNameWarning && e.target.value == ""
        })
    }

    lastNameChangeHandler(e) {
        this.setState({
            lastName: e.target.value,
            lastNameWarning: this.state.lastNameWarning && e.target.value == ""
        })
    }

    cityChangeHandler(e) {
        this.setState({
            city: e.target.value,
            cityWarning: this.state.cityWarning && e.target.value == ""
        })
    }

    streetChangeHandler(e) {
        this.setState({
            street: e.target.value,
            streetWarning: this.state.streetWarning && e.target.value == ""
        })
    }

    houseNumberChangeHandler(e) {
        this.setState({
            houseNumber: e.target.value,
            houseNumberWarning: this.state.houseNumberWarning && e.target.value == ""
        })
    }

    localNumberChangeHandler(e) {
        this.setState({
            localNumber: e.target.value
        })
    }

    postCodeChangeHandler(e) {
        this.setState({
            postCode: e.target.value,
            postCodeWarning: this.state.postCodeWarning && e.target.value == ""
        })
    }

    postChangeHandler(e) {
        this.setState({
            post: e.target.value,
            postWarning: this.state.postWarning && e.target.value == ""
        })
    }

    phoneNumberChangeHandler(e) {
        this.setState({
            phoneNumber: e.target.value,
            phoneWarning: this.state.phoneWarning && e.target.value == ""
        })
    }

    emailChangeHandler(e) {
        this.setState({
            email: e.target.value,
            emailWarning: this.state.emailWarning && e.target.value == ""
        })
    }

    commentsChangeHandler(e) {
        this.setState({
            comments: e.target.value
        })
    }

    regulationChangeHandler(e) {
        this.setState({
            regulation: e.target.checked,
            regulationWarning: e.target.regulationWarning
        })
    }

    newsletterChangeHandler(e) {
        this.setState({
            newsletter: e.target.checked,
        })
    }

    paczkomatOnClickHandler() {

        if (!this.state.showedPaczkomatChooser) {
            window.loadPaczkomat();
        }

        this.setState({
            showPaczkomatChooser: true,
            showedPaczkomatChooser: true
        })
    }

    async makeOrder() {

        if (this.props.cart.products == null || this.props.cart.products.length == 0) {
            return;
        }

        if (!this.validateForm()) {
            return;
        }

        const order =
        {          
            comments: this.state.comments,
            shippingMethodId: this.props.cart.shippingMethod.shippingMethodId,
            newsletter: this.state.newsletter,
            promoCode: this.props.cart.promoCode ? this.props.cart.promoCode.code : null,
            makeOrderUserDto: {
                userId: null,
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                country: "Polska",
                city: this.state.city,
                street: this.state.street,
                houseNumber: this.state.houseNumber,
                localNumber: this.state.localNumber,
                postCode: this.state.postCode,
                post: this.state.post,
                phoneNumber: this.state.phoneNumber,
                paczkomatCode: this.state.paczkomatCode,             
            },
            makeOrderItemDtos: []
        }

        //dodanie produktów do zamówienia
        for (let product of this.props.cart.products) {
            let orderItem = { productId: product.id, count: product.count }
            order.makeOrderItemDtos.push(orderItem);
        }

        this.setState({
            loading: true
        })

        const apiController = new APIController();
        const response = await apiController.post('api/order',order);

        if (response.ok) {
            this.props.cart.empty();
            this.clearForm();
            this.setState({
                orderDone: true,
                orderFailed: null
            })
        }
        else {
            this.setState({
                orderFailed: "Wystąpiły błędy podczas składania zamówienia"
            })

        }


        this.setState({
            loading: false
        })
    }

    validateForm() {
        let firstNameWarning = this.state.firstName == "";
        let lastNameWarning = this.state.lastName == "";
        let cityWarning = this.state.city == "";
        let streetWarning = this.state.street == "";
        let houseNumberWarning = this.state.houseNumber == "";
        let postCodeWarning = this.state.postCode == "";
        let postWarning = this.state.post == "";
        let phoneWarning = this.state.phoneNumber == "";
        let emailWarning = !EmailValidator.isEmailValid(this.state.email);
        let regulationWarning = !this.state.regulation;

        let paczkomatCodeWarning = false;
        if (this.props.cart.shippingMethod != null && this.props.cart.shippingMethod.name === 'Paczkomat')
            paczkomatCodeWarning = this.state.paczkomatCode == "";

        this.setState({
            firstNameWarning: firstNameWarning,
            lastNameWarning: lastNameWarning,
            cityWarning: cityWarning,
            streetWarning: streetWarning,
            houseNumberWarning: houseNumberWarning,
            postCodeWarning: postCodeWarning,
            postWarning: postWarning,
            phoneWarning: phoneWarning,
            emailWarning: emailWarning,
            regulationWarning: regulationWarning,
            paczkomatCodeWarning: paczkomatCodeWarning
        })

        if (firstNameWarning || lastNameWarning || cityWarning || streetWarning || houseNumberWarning || postCodeWarning || postWarning || phoneWarning || emailWarning || paczkomatCodeWarning || regulationWarning) {
            return false;
        }    

        return true;
    }

    clearForm() {
        this.setState({
            firstName: "",
            lastName: "",
            city: "",
            street: "",
            houseNumber: "",
            localNumber: "",
            postCode: "",
            post: "",
            phoneNumber: "",
            email: "",
            comments: "",
            paczkomatCode: ""
        });
    }
}

