import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import BaseComponent from './base/BaseComponent';

export default class Product extends BaseComponent {

    state = {
        count: 1
    }

    render() {
        return (
            <figure className="product" onClick={() => this.openDetails()}>
                {this.renderHiglighted()}
                {this.renderImageContainer()}
                {this.renderDescription()}
                {this.renderAddToCartContainer()}
            </figure>
        );
    }

    renderImageContainer() {
        return (
            <div className="product__image-container">
                
                {
                    this.props.product.image === null ?
                        <h2>Brak zdjęcia</h2>
                        :
                        <img src={this.props.product.image} alt="Brak zdjęcia"></img>
                }
            </div>
            )
    }

    renderHiglighted() {
        return (
            this.props.product.highlighted &&
            <i className="--highlighted fa fa-star" aria-hidden="true"></i>
        )
    }

    renderDescription() {
        return (
            <figcaption>
                <h2 className="product__name">{this.props.product.name}</h2>
                {/*<p className="product__description">{this.props.product.description}</p>*/}
                <div className="product__price">{this.props.product.price} zł</div>
            </figcaption>
        )
    }

    renderAddToCartContainer() {
        return(
            this.props.product.available ?
                <div className="product__add-to-cart-conatiner" onClick={(e) => e.stopPropagation()}>
                    <input type="number" className="product__count" name="count" min="1" max="50" value={this.state.count} onChange={(e) => this.handleInputChange(e)}></input>
                    <div className="product__button" onClick={(e) => { this.addToCart(this.props.product) }}> DO KOSZYKA<i className="fa fa-cart-plus" aria-hidden="true"></i></div>
            </div>
            :
            <div className="product__add-to-cart-conatiner">
                <p>Produkt chwilowo niedostępny</p>
                </div>
            )
    }

    addToCart(product) {
        let count;
        if (!this.state.count)
            count = 1;
        else
            count = parseInt(this.state.count);

        this.props.cart.addProduct(product, count);
    }

    openDetails() {
        this.props.history.push('/produkt/' + this.props.product.id);
    }
}