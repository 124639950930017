import DetailsComponent from './base/DetailsComponent';

export default class AdminPanelCategory extends DetailsComponent {
    urlApi = '/api/category'
    urlBack = '/panel/kategorie'
    mapping = {
        name: 'Nazwa',
    };

    mappingTypes = {
        name: 'text',
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }
}