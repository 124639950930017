import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import InfoLoad from './InfoLoad'
import InfoCartAdd from './InfoCartAdd';
import CartModel from '../CartModel';
import APIController from '../APIController'
import DetailsComponent from './base/DetailsComponent';

export default class ProductDetails extends DetailsComponent {

    state = {
        count: 1,
        entity: null
    }

    urlApi = '/api/product'
    registrationEntry = () => { return 'Podgląd produktu ' + this.state.entity ? this.state.entity.name : '' }


    render() {
        return (
            this.state.entity === null ?
                this.state.loading ? < InfoLoad message={"Wczytywanie..."} /> : null
                :
                <div>
                    {this.props.cart.addedProduct && <InfoCartAdd cart={this.props.cart} product={this.props.cart.addedProduct} />}

                    <div className="productDetails">
                        <div className="productDetails__imageContainer">
                            {
                                this.state.entity.image === null ?
                                    <h2>Brak zdjęcia</h2>
                                    :
                                    <img src={this.state.entity.image} alt="Brak zdjęcia"></img>
                            }
                        </div>
                        <div className="productsDetails__container">
                            <h2>{this.state.entity.name}</h2>
                            <h3><p>{this.state.entity.price} zł</p></h3>
                            <p><div dangerouslySetInnerHTML={{ __html: this.state.entity.description }} /></p>
                            <p>Kategoria: {this.state.entity.category}</p>

                            {
                                this.state.entity.available ?
                                    <div className="productDetails__add-to-cart-conatiner">
                                        <input type="text" className="productDetails__count" value={this.state.count} onChange={this.countChange.bind(this)}></input>
                                        <div className="productDetails__button" onClick={() => { this.addToCart(this.props.product) }}> DO KOSZYKA<i className="fa fa-cart-plus" aria-hidden="true"></i></div>
                                    </div>
                                    :
                                    <div className="productDetails__add-to-cart-conatiner">
                                        <p>Produkt chwilowo niedostępny</p>
                                    </div>
                            }
                            <NavLink exact to="/"><div className="__btn">Powrót do sklepu</div></NavLink>
                        </div>
                    </div>
                </div>
        );

    }

    addToCart() {
        let count;
        if (this.state.count === '')
            count = 1;
        else
            count = parseInt(this.state.count);

        this.props.cart.addProduct(this.state.entity, count);
    }

    countChange(e) {
        let result = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        if (parseInt(result) <= 0)
            result = 1;
        if (parseInt(result) > 100)
            result = 100;

        this.setState({
            count: result
        })
    }
}