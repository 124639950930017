import GridComponent from './base/GridComponent';

export default class AdminPanelCategories extends GridComponent {
    className = 'adminpanel-categories';
    mapping = {
        name: 'Nazwa',

    };
    urlApi = 'api/category';
    urlDetails = '/panel/kategoria';
    urlBack = '/panel';

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }
}