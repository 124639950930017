class APIController {

    constructor(token) {
        this.token = token;
    }

    async getOrders() {
        const response = await this.get('api/order');
        return await response.json();
    }

    async changeOrder(order,token) {
        console.log(JSON.stringify(order));

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(order)
        };
        const response = await fetch('api/order', requestOptions);
    }

    async login(login) {
        const response = await this.post('api/user/login', login);
        return await response.json();
    }

    async getShippingMethods() {
        const response = await this.get('api/shippingMethod');
        return await response.json();
    }

    async getProducts() {
        const response = await this.get('api/product')
        return await response.json();
    }

    async getProduct(productId) {
        const response = await this.get('api/product/' + productId)
        return await response.json();
    }

    async postProduct(product) {
        try {
            await this.post('api/product', product)
            return true;
        }
        catch{
            return false;
        }
    }

    async putProduct(product) {
        try {
            await this.put('api/product', product);
            return true;
        }
        catch {
            return false
        }
    }

    async getCategories() {
        const response = await this.get('api/category');
        return await response.json();
    }

    async postCategory(category) {
        try {
            await this.post('api/category', category);
            return true;
        }
        catch{
            return false;
        }   
    }

    async get(url) {
        return await this.get(url, null);
    }

    async get(url, data) {
        return await this.request('GET', url, data);
    }

    async post(url, data) {
        return await this.request('POST', url, data);
    }

    async put(url, data) {
        return await this.request('PUT', url, data);
    }

    async delete(url) {
        return await this.request('DELETE', url, null);
    }

    async request(method, url, data) {
        const requestOptions = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (this.token != null)
            requestOptions.headers['Authorization'] = `Bearer ${this.token}`;
        if (data != null)
            requestOptions.body = JSON.stringify(data);   

        const response = await fetch(url, requestOptions);
        return response;
    }


}
export default APIController;