import React from 'react';

export const UserContext = React.createContext({
    user: {},
    token: null,
    setToken(token) {
        this.token = token
        localStorage.setItem('token', token)
    },
    getToken() {
        if (this.token === null) {
            const tokenFromStorage = localStorage.getItem('token');
            this.token = tokenFromStorage;
            return tokenFromStorage
        }
        else
            return this.token;
    },
    logout() {
        this.token = null;
        localStorage.removeItem('token');
        this.refreshHeaderLogin(false);
    },
    refreshHeaderLogin: () => { }

});