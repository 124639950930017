import React, { Component } from 'react';
import APIController from '../APIController'
import { UserContext } from '../UserContex'
import DetailsComponent from './base/DetailsComponent';

export default class AdminPanelShippingMethod extends DetailsComponent {
    urlApi = '/api/shippingMethod'
    urlBack = '/panel/metody-wysylki'
    mapping = {
        name: 'Nazwa',
        cost: 'Koszt',
        freeCostTreshold: 'Próg darmowej wysyłki'
    };

    mappingTypes = {
        name: 'text',
        cost: 'number',
        freeCostTreshold: 'number'
    }

    mappingValidate = {
        name: (v) => { return v ? true : false },
        cost: (v) => { if (v > 0) return true; else return false }
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }
}
