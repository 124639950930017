import React, { Component } from 'react';
import Product from './Product';
import Header from './Header';
import APIController from '../APIController'
import CartModel from '../CartModel'
import InfoLoad from './InfoLoad'
import InfoCartAdd from './InfoCartAdd';
import Newsletter from './Newsletter';
import { UserContext } from '../UserContex';
import BaseComponent from './base/BaseComponent';


export default class Main extends BaseComponent {
    state = {
        products: [],
        categories: [],
        categoryId: 0,
        sort: 'category',     
        loading: false
    }

    async componentDidMount() {
        this.setState({
            loading: true
        })
        await this.loadProducts();
        await this.loadCategories();
        await this.registerEntry();
        this.setState({
            loading: false
        })
        window.scrollTo(0, 0);
    }

    sort(products) {
        let sortedProducts = [];

        //sortowanie po wybranym spodobie sortowania
        switch (this.state.sort) {
            case 'price_asc': sortedProducts = products.sort((p1, p2) => p1.price - p2.price); break;
            case 'price_desc': sortedProducts = products.sort((p1, p2) => p2.price - p1.price); break;
            case 'name_asc': sortedProducts = products.sort((p1, p2) => (p1.name > p2.name) ? 1 : ((p1.name < p2.name) ? -1 : 0)); break;
            case 'category': sortedProducts = products.sort((p1, p2) => (p1.category > p2.category) ? 1 : ((p1.category < p2.category) ? -1 : 0)); break; 
        }
        //umieszczenie wyróżnionych produktów na początku listy
        sortedProducts = sortedProducts.sort((p1, p2) => (p1.highlighted && p2.highlighted || !p1.highlighted && !p2.highlighted) ? 0 : (p1.highlighted ? -1 : 1));
        //umieszczenie produktów chwilowo niedostępnych na końcu listy
        sortedProducts = sortedProducts.sort((p1, p2) => (p1.available && p2.available || !p1.available && !p2.available) ? 0 : (p1.available ? -1 : 1));

        return sortedProducts;
    }

    filter(products) {
        let filteredProducts = [];
        //ukrywanie produktów nieaktualnych
        filteredProducts = products.filter((p) => { return !p.outDated });
        //filtrowanie po kategorii
        filteredProducts = filteredProducts.filter((product) => { return this.state.categoryId === 0 || product.categoryId === this.state.categoryId });

        return filteredProducts;
    }

    productsList() {
        let products = this.state.products;
        products = this.filter(products);
        products = this.sort(products);
        return products;
    }

    render() {
        return (
            
            <div className="main">
                {this.state.loading && < InfoLoad message={"Wczytywanie..."} />}
                {this.props.cart.addedProduct && <InfoCartAdd cart={this.props.cart} product={this.props.cart.addedProduct} />}
                {this.renderSentence()}
                <hr />
                {this.renderSelections()}
                {this.renderProducts()}
                <Newsletter />
            </div>
        );
    }

    renderSentence() {
        return (
            <div className="sentence">
                <blockquote><i>"Bo życie jest zbyt krótkie by pić przeciętne wina"</i></blockquote>
            </div>
            )
    }

    renderSelections() {
        return (
            <div className="products__selections">
                {this.renderCategorySelection()}
                {this.renderSortSelection()}
            </div>
            )
    }

    renderCategorySelection() {
        return (
            <div className="products__selection">
                <label>Kategoria</label>
                <select className="" value={this.state.categoryId} onChange={(e) => this.categoryChange(e)} name="categories" id="categories">
                    <option key={0} value={0}>Wszystkie</option>
                    {
                        this.state.categories.map((category) =>
                            <option key={category.categoryId} value={category.categoryId}>{category.name}</option>
                        )
                    }
                </select>
            </div>
            )
    }

    renderSortSelection() {
        return (
            <div className="products__selection">
                <label>Sortuj</label>
                <select className="" value={this.state.sort} onChange={(e) => this.sortChange(e)} name="sort" id="sort">
                    <option key={0} value={'price_asc'}>Cena od najniższej</option>
                    <option key={1} value={'price_desc'}>Cena od najwyższej</option>
                    <option key={2} value={'name_asc'}>Nazwa</option>
                    <option key={3} value={'category'}>Kategoria</option>
                </select>
            </div>
            )
    }

    renderProducts() {
        return (
            <div className="product-box">
                {
                    this.productsList().map((product) =>
                        <Product key={product.id} product={product} cart={this.props.cart} history={this.props.history} />)
                }
            </div>
            )
    }

    categoryChange(e) {
        const categoryId = parseInt(e.target.value);
        this.setState({
            categoryId: categoryId
        })
    }

    sortChange(e) {
        this.setState({
            sort: e.target.value
        })
    }

    async loadProducts() {
        const apiController = new APIController();
        //let url = 'api/product?count=4&offset=4&outdated=false';
        let url = 'api/product';
        const response = await apiController.get(url);
        if (response.ok) {
            const products = await response.json();
            this.setState({
                products: products
            })
        }
        
    }

    async loadCategories() {
        const apiController = new APIController();
        const categories = await apiController.getCategories();
        if (categories === null)
            return;
        else
            this.setState({ categories: categories })
    }

    async registerEntry() {
        const entry = {
            page: window.location.href.includes('fbcid') ? 'Sklep (facebook)' : 'Sklep'
        }

        const apiController = new APIController();
        await apiController.post('api/entry', entry);
    }
}