import React from 'react';
import BaseComponent from './base/BaseComponent';
import EmailValidator from '../helpers/EmailValidator'

export default class Header extends BaseComponent {
    state = {
        email: '',
        infoOK: false,
        validateError: null
    }

    async componentDidMount() {

    }

    render() {
        return (
            (false === true) ?

                <div className="newsletter">
                    <h2>Newsletter</h2>
                    <p>Zapisz się do naszego newslettera i otrzymuj informacje na temat promocji i okazji w naszym sklepie. Będziemy również informować Cię o ciekawych wydarzaniach odbywających się na winnicy</p>
                    <input type="email" name="email" value={this.state.email} onChange={(e) => this.handleInputChange(e)} />
                    <button onClick={() => this.subscribe()}>SUBSKRYBUJ</button>
                    {this.state.infoOK && <p className="newsletter_info">Dodano Twój adres email do Newslettera</p>}
                    {this.state.validateError && <p className="newsletter_info">{this.state.validateError}</p>}
                </div >
                :
                null
        )
    }

    async subscribe() {
        if (this.validate()) {
            const userDto = {
                email: this.state.email
            }
            const response = await this.apiController.post('api/user/subscribe', userDto);
            if (response.ok) {
                this.setState({ infoOK: true, email: '', validateError: false })
            }
            else if (response.status === 400) {
                this.setState({ validateError: "Podany adres email jest już zarejestrowany do newlettera" })
            }
        }
        else {
            this.setState({ validateError: "Niepoprawny adres email" })
        }
    }

    validate() {
        return EmailValidator.isEmailValid(this.state.email);
    }
}