import React, { Component } from 'react';
import BaseComponent from './BaseComponent'

export default class GridComponent extends BaseComponent {

    mapping = {};
    urlApi = '';
    urlDetails = '';
    readonly = false;
    filterRow = true;
    renderEditDeleteButtonsFlag = true;

    //mapowanie niestandardowego wyświetlania wartości komórek grida dla danego pola
    mappingCustomGridCellValue = {}

    loadData = async () => {
        const response = await this.apiController.get(this.urlApi);
        if (response.ok) {
            const entities = await response.json();
            this.setState({
                entities: entities
            })
        }
    }

    constructor(props, context) {
        super(props, context);
    }

    state = {
        entities: [],
        filtering: {},
        sorting: {}
    }



    gridEntities() {
        let gridEntities = this.state.entities;
        gridEntities = this.filterGridEntities(gridEntities);
        gridEntities = this.sortGridEntities(gridEntities);
        return gridEntities;
    }

    filterGridEntities(entities) {
        for (let field in this.state.filtering) {
            if (this.state.filtering[field])
                entities = entities.filter((e) => { return e[field].toString().toLowerCase().includes(this.state.filtering[field].toLowerCase()) })        
        }
        return entities;
    }

    sortGridEntities(entities) {
        return entities;
    }

    renderEntitiesTable() {

        if (this.state.entities == null)
            return null;

        return (
            <table className="grid__table">
                {this.renderEntitiesTableHeader()}
                {this.renderEntitiesTableBody()}
                <tfoot></tfoot>
            </table>
        )
    }

    renderEntitiesTableHeader() {
        return (
            <thead>
                {this.renderEntitiesTableHeaderRow()}
            </thead>
        )
    }

    renderEntitiesTableHeaderRow() {
        return (
            <tr>
                {
                    Object.entries(this.mapping).map((m, i) =>
                        <th key={i}>{m[1]}</th>
                    )
                     
                }
                {
                    this.renderEditDeleteButtonsFlag &&
                    <>
                        <th className="grid__button-cell"></th>
                        <th className="grid__button-cell"></th>
                    </>
                }    
                
            </tr>
        )
    }

    renderEntitiesTableBody() {
        return (
            <tbody>
                {this.filterRow && this.renderEntitiesTableBodyFilterRow()}
                {
                    this.gridEntities().map((e, i1) =>
                        this.renderEntitiesTableBodyRow(e,i1)
                    )}
            </tbody>
        )
    }

    renderEntitiesTableBodyFilterRow() {
        return (
            <tr>
                {Object.entries(this.mapping).map((m, i2) =>
                    this.renderEntitiesTableBodyFilterCell(m[0],i2)
                )}
                {
                    this.renderEditDeleteButtonsFlag &&
                    <>
                        <td className="grid__button-cell"></td>
                        <td className="grid__button-cell"></td>
                    </>
                }               
            </tr>
            )
    }

    renderEntitiesTableBodyFilterCell(field, index) {
        return (
            <td key={index}>
                <input type="text" name={ 'filtering.' +  field} value={this.state.filtering[field]} onChange={(e) => this.handleInputChange(e)} />
            </td>
            )
    }

    renderEntitiesTableBodyRow(e,i1) {
        return (
            <tr key={i1}>
                {Object.entries(this.mapping).map((m, i2) =>
                    this.renderEntitiesTableBodyCell(e, m[0], i2)
                )}
                {
                    (this.renderEditDeleteButtonsFlag && !this.readonly) &&
                    <>
                        <td className="grid__button-cell"><button onClick={() => this.openDetails(e[Object.keys(e)[0]])}><i class="fa fa-pencil" aria-hidden="true"></i></button></td>
                        <td className="grid__button-cell"><button onClick={() => this.delete(e[Object.keys(e)[0]])}><i class="fa fa-trash" aria-hidden="true"></i></button></td>
                    </>
                }  
                {this.renderEntitiesTableBodyRowAdditionalFields(e)}
            </tr>
            )
    }

    renderEntitiesTableBodyCell(entity, field, index) {
        let value = entity[field]
        let valueFormatted = value;
        if (value === true)
            valueFormatted = "Tak";
        if (value === false)
            valueFormatted = "Nie";
        if (this.mappingCustomGridCellValue[field] != null)
            valueFormatted = this.mappingCustomGridCellValue[field](value);
        return (
            <td key={index}>{valueFormatted}</td>
            )
    }

    renderEntitiesTableBodyRowAdditionalFields(e) {

    }

    renderButtons() {
        return (
            <div className="grid__buttons">
                {!this.readonly &&  < button onClick={() => this.openDetails('')}>Dodaj</button>}
                <button onClick={() => this.back()}>Powrót</button>
            </div>
            )
    }

    baseRender() {
        return (
            <div className={this.className}>
                {this.infoLoad()}
                {this.renderEntitiesTable()}
                {this.renderButtons()}
            </div>
        )
    }

    render() {
        return (
            <div className="grid">
                {this.baseRender()}              
            </div>
        )
    }

    openDetails(id) {
        this.props.history.push(this.urlDetails + '/' + id);
    }

    async delete(id) {
        if (window.confirm("Czy na pewno usunąć?")) {
            const response = await this.apiController.delete(this.urlApi + '/' + id);

            if (response.ok) {
                const entities = this.state.entities.filter(e => e[Object.keys(e)[0]] !== id);
                this.setState({
                    entities: entities
                })
            }
        }
    }


}