import React, { Component } from 'react';
import APIController from '../APIController'
import { UserContext } from '../UserContex'


export default class Login extends Component {
    state = {
        email: "",
        password: ""
    }

    render() {
        return (
            <div className="login">
                <form>
                    <label>Email</label><input type="text" value={this.state.email} onChange={this.emailChange.bind(this)}></input>
                    <label>Hasło</label><input type="password" value={this.state.password} onChange={this.passwordChange.bind(this)}></input>
                    
                </form>
                <button onClick={() => this.login()}>Zaloguj</button>
            </div>
            )
    }

    emailChange(e) {
        this.setState({
            email: e.target.value
        })
    }

    passwordChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    async login() {
        const login = {
            email: this.state.email,
            password: this.state.password
        }

        const apiController = new APIController(this.context.getToken());
        const token = await apiController.login(login);
        if (token !== null) {
            this.context.setToken(token.token);
            window.location.href = window.location.origin + '/panel';
        }
        
    }
}
Login.contextType = UserContext;

