import React, { Component } from 'react';
import APIController from '../APIController'
import { UserContext } from '../UserContex'
import GridComponent from './base/GridComponent';

export default class AdminPanelUsers extends GridComponent {

    className = 'adminpanel-users';
    mapping = {
        email: 'Email',
        newsletter: 'Newsletter',
        account: 'Account'
    };
    urlApi = 'api/user';
    urlDetails = '/panel/uzytkownik';
    urlBack = '/panel';
    readonly = true;

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    render()
    {
        return (
            <div>
                
                {super.render()}
                <div>
                    <h3>Wyślij wiadomość do subskrybentów</h3>
                    <textarea></textarea>
                    <button>Wyślij</button>
                </div>
            </div>
            )
    }
}