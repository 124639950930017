import DetailsComponent from './base/DetailsComponent';

export default class AdminPanelConfiguration extends DetailsComponent {
    urlApi = '/api/configuration'
    urlBack = '/panel'
    mapping = {
        sendPromoCodeOnNewsletterSubscribe: 'Kod rabatowy dla subskrybenta'
    };

    mappingTypes = {
        sendPromoCodeOnNewsletterSubscribe: 'checkbox'
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    loadData = async () => {
        const response = await this.apiController.get(this.urlApi);
        console.log(response);
        if (response.ok) {
            const entity = await response.json();
            
            this.setState({
                entity: entity
            });
        }
    }

    async confirm() {

        if (this.validate()) {
            const entity = this.state.entity;
            

            const response = await this.apiController.put(this.urlApi, entity);

            if (response.ok) {
                this.back();
            }
        }

    }
}