import React from 'react';
import BaseComponent from './BaseComponent'

export default class DetailsComponent extends BaseComponent {

    mappingValidate = {};

    loadData = async () => {
        await this.loadDictionaries();
        if (this.isEdit()) {
            const response = await this.apiController.get(this.urlApi + '/' + this.props.match.params.id);

            if (response.ok) {
                const entity = await response.json();

                this.setState({
                    entity: entity
                });
            }
        }
        else {
            this.setDefaults();
        }
    }

    setDefaults() {

    }

    state = {
        entity: {},
        validateErrors: {}
    }

    isEdit() {
        return this.props.match.params.id != null
    }

    renderInputs() {

        return (
            <div className="details__inputs">
                {
                    Object.entries(this.mapping).map((m, i) =>
                        <label className="details__input">
                            <span>{m[1]}</span>
                            {this.renderInput(m[0])}
                        </label>
                    )
                }
            </div>
        )
    }

    renderInput(field) {

        const type = this.mappingTypes[field];

        switch (type) {
            case 'label': return <label>{this.state.entity[field]}</label>
            case 'text': return <input className={this.state.validateErrors[field] ? 'warning' : ''} type="text" name={'entity.' + field} value={this.state.entity[field]} onChange={(e) => this.handleInputChange(e)} />
            case 'number': return <input className={this.state.validateErrors[field] ? 'warning' : ''} type="number" name={'entity.' + field} value={this.state.entity[field]} onChange={(e) => this.handleInputChange(e)} />
            case 'checkbox': return <input className={this.state.validateErrors[field] ? 'warning' : ''} type="checkbox" name={'entity.' + field} checked={this.state.entity[field]} onChange={(e) => this.handleInputChange(e)} />
            case 'date': return <input className={this.state.validateErrors[field] ? 'warning' : ''} type="date" name={'entity.' + field} value={this.state.entity[field] ? this.state.entity[field].substring(0, 10) : null} onChange = {(e) => this.handleInputChange(e)} />
            case 'textarea': return <textarea className={this.state.validateErrors[field] ? 'warning' : ''} name={'entity.' + field} value={this.state.entity[field]} onChange={(e) => this.handleInputChange(e)} />
            case 'selection':
                return (
                    <select className={this.state.validateErrors[field] ? 'warning' : ''} name={'entity.' + this.mappingDictionaries[field]} value={this.state.entity[this.mappingDictionaries[field]]} onChange={(e) => this.handleInputChange(e)}>
                        <option disabled selected value>-- wybierz --</option>
                        {
                            this.state[field] &&
                            this.state[field].map(d =>
                                <option value={d[this.mappingDictionaries[field]]}>{d[this.mappingDictionariesDisplay[field]]}</option>
                            )
                        }
                    </select>
                )
            case 'select-multiple':
                return (
                    <select multiple className={this.state.validateErrors[field] ? 'warning' : ''} name={'entity.' + field} value={this.state.entity[field]} onChange={(e) => this.handleInputChange(e)}>
                        {
                            this.state[field] &&
                            this.state[field].map(d =>
                                <option value={d[this.mappingDictionaries[field]]}>{d[this.mappingDictionariesDisplay[field]]}</option>
                            )
                        }
                    </select>
                )
            case 'image':
                return (
                    <div className="image-file">
                        <img src={this.state.entity[field]} />
                        <input className={this.state.validateErrors[field] ? 'warning' : ''} type="file" name={'entity.' + field} onChange={(e) => { this.handleInputChange(e) }} />
                    </div>
                    )
            default: return <input type="text" name={'entity.' + field} value={this.state.entity[field]} onChange={(e) => this.handleInputChange(e)} />
        }
    }
   

    renderButtons() {
        return (
            <div className="details__buttons">
                <button onClick={() => this.confirm()}>Zatwierdź</button>
                <button onClick={() => this.back()}>Powrót</button>
            </div>
            )
    }

    render() {
        return(
        <div className="details">
                {this.baseRender()}
                {this.renderTitle()}
                {this.renderInputs()}
                {this.renderButtons()}
        </div>)
    }

    validate() {
        let result = true;
        Object.entries(this.mappingValidate).map((m) => {
            if (!m[1](this.state.entity[m[0]])) {

                const validateErrors = this.state.validateErrors ?? {}
                validateErrors[m[0]] = true; 
                this.setState({
                    validateErrors: validateErrors
                })
                result = false;
            }
        })

        return result;
    }

    async confirm() {

        if (this.validate()) {
            const entity = this.state.entity;

            let response;
            if (this.isEdit())
                response = await this.apiController.put(this.urlApi, entity);
            else
                response = await this.apiController.post(this.urlApi, entity);

            if (response.ok) {
                this.back();
            }
        }
        
    }
}