import React from 'react';
import GridComponent from './base/GridComponent';

export default class AdminPanelOrders extends GridComponent {
    state = {
        filtering: {
            status: 'w realizacji'
        }
    }

    className = 'adminpanel-orders';
    mapping = {
        orderId: 'Nr zamówienia',
        date: 'Data zamówienia',
        data: 'Dane',
        addres: 'Adres',
        shippingMethodName: 'Metoda wysyłki',
        products: 'Produkty',
        cost: 'Koszt',
        comments: 'Uwagi',
        status: 'Status'
    };
    urlApi = 'api/order';
    urlDetails = '/panel/zamowienie';
    urlBack = '/panel';

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    renderEntitiesTableHeaderRow() {
        return (
            <tr>
                <th>Nr</th>
                <th>Data</th>
                <th>Dane</th>
                <th>Adres</th>
                <th>Wysyłka</th>
                <th>Produkty</th>
                <th>Koszt</th>
                <th>Uwagi</th>
                <th>Status</th>
                <th className="grid__button-cell"></th>
                <th className="grid__button-cell"></th>
            </tr>

            )
    }

    renderEntitiesTableBodyFilterRow() {
        return (
            <tr>
                <td><input type="text" name={'filtering.orderId'} value={this.state.filtering['orderId']} onChange={(e) => this.handleInputChange(e)} /></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><input type="text" name={'filtering.status'} value={this.state.filtering['status']} onChange={(e) => this.handleInputChange(e)} /></td>
                <td className="grid__button-cell"></td>
                <td className="grid__button-cell"></td>
            </tr>
        )
    }

    renderEntitiesTableBodyRow(e, i1) {
        return (
            <tr key={i1}>
                <td>{e.orderId}</td>
                <td>{e.date.slice(0, 10)}</td>
                <td>
                    <p>Imię: {e.makeOrderUserDto.firstName}</p>
                    <p>Nazwisko: {e.makeOrderUserDto.lastName}</p>
                    <p>Telefon: {e.makeOrderUserDto.phoneNumber}</p>
                    <p>Email: {e.makeOrderUserDto.email}</p>
                </td>
                <td>
                    <p>Miejscowość: {e.makeOrderUserDto.city}</p>
                    <p>Ulica: {e.makeOrderUserDto.street}</p>
                    <p>Numer domu: {e.makeOrderUserDto.houseNumber}</p>
                    <p>Numer lokalu: {e.makeOrderUserDto.localNumber}</p>
                    <p>Kod pocztowy: {e.makeOrderUserDto.postCode}</p>
                    <p>Poczta: {e.makeOrderUserDto.post}</p>
                </td>
                <td>
                    {e.shippingMethodName}
                </td>
                <td>
                    {
                        e.makeOrderItemDtos.map((orderItem) =>
                            <p>{orderItem.count + 'x ' + orderItem.name}</p>
                        )
                    }
                </td>
                <td>{e.cost} zł</td>
                <td>{e.comments}</td>
                <td>
                    <select value={e.status} onChange={(event) => this.changeStatus(event,e)}>
                        <option>w realizacji</option>
                        <option>wysłano</option>
                        <option>anulowano</option>
                    </select>
                </td>
                <td className="grid__button-cell">
                    <button onClick={() => this.openDetails(e.orderId)}><i class="fa fa-eye" aria-hidden="true"></i></button>
                </td>
                <td className="grid__button-cell">
                    <button onClick={() => this.delete(e.orderId)}><i class="fa fa-trash" aria-hidden="true"></i></button>
                </td>
                
            </tr>)
    }

    renderButtons() {
        return (
            <div className="grid__buttons">
                <button onClick={() => this.back()}>Powrót</button>
            </div>
        )
    }

    async changeStatus(event,entity) {
        const status = event.target.value;
        const entities = this.state.entities;
        const oldEntity = entities.find(element => element[Object.keys(element)[0]] === entity[Object.keys(entity)[0]]);
        oldEntity.status = status;
        const response = await this.apiController.put(this.urlApi, oldEntity);
        if (response.ok) {
            this.setState({
                entities: entities
            })
        }
    }

    async delete(id) {
        if (window.confirm("Czy na pewno usunąć zamówienie?")) {
            const response = await this.apiController.delete('api/order/' + id);

            if (response.ok) {
                //usunięcie zamówienia z listy
                const entities = this.state.entities.filter(e => e.orderId !== id);
                this.setState({
                    entities: entities
                })
            }
        }
    }
}