import APIController from "./APIController";

export default class PayU {


    async request(method, url, data) {
        const requestOptions = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (this.token != null)
            requestOptions.headers['Authorization'] = `Bearer ${this.token}`;
        if (data != null)
            requestOptions.body = JSON.stringify(data);

        const response = await fetch(url, requestOptions);
        console.log(response);

        return response;
    }




    async authorize(serverToken) {
        const apiController = new APIController(serverToken);
        apiController.post('api/payu', null);

        //await this.request('POST', 'https://secure.payu.com/pl/standard/user/oauth/authorize?grant_type=client_credentials&client_id=145227&client_secret=12f071174cb7eb79d4aac5bc2f07563f', null);


        const requestOptions = {
            method: 'POST',
        }

        const response = await fetch('https://secure.payu.com/pl/standard/user/oauth/authorize?', requestOptions);
        console.log(response);
    }

    async pay(serverToken, extOrderId, buyer, products) {
        const request = {
            //extOrderId: extOrderId,            
            buyer: buyer,
            products: products
        }

        const apiController = new APIController(serverToken);
        const response = await apiController.post('api/payu', request);
        if (response.ok) {
            const responseJson = await response.json();
            const redirectUri = responseJson.redirectUri;
            console.log(redirectUri);
            return redirectUri;
        }

        return null;
    }

    async payPrzelewy24(serverToken, sessionId, amount, cart, shippingType, shippingCost, email, client, address, zip, city, country, phone) {
        const request = {
            sessionId: sessionId,
            amount: amount,
            //cart: cart,
            shipping: shippingCost,
            email: email,
            client: client,
            address: address,
            zip: zip,
            city: city,
            phone: phone,
            additional: {
                shipping: {
                    type: shippingType,
                    address: address,
                    zip: zip,
                    city: city,
                    country: country
                }
            }
        }


        const apiController = new APIController(serverToken);
        const response = await apiController.post('api/przelewy24', request);
        if (response.ok) {
            const responseJson = await response.json();
            const redirectUriToken = responseJson.data.token;
            console.log(redirectUriToken);
            return redirectUriToken;
        }
    }
}

