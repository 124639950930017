import APIController from "../APIController";

class EntryController {

    apiController = new APIController();


    async registerEntry(object) {
        const entry = {
            page: object
        }
        await this.apiController.post('api/entry', entry);
    }
}
export default EntryController;