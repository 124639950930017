import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { browserHistory } from "react-router";
import BaseComponent from './base/BaseComponent';

export default class Header extends BaseComponent {
    constructor(props, context) {
        super(props, context);
        this.context.refreshHeaderLogin = (login) => { this.setHeaderLogin(login) };
    }

    setHeaderLogin(login) {
        this.setState({ login: login })
        window.location.href = window.location.origin;
    }

    componentDidMount() {
        this.setState({ login: this.context.getToken() });
    }


    render() {
        return (
            <div className="header">
                <NavLink exact to="/">
                    <img className="header__logo" src="img/logo_pelne2.png"></img>
                    {/*<img className="header__logo-text" src="img/logo_napis.png"></img>*/}
                </NavLink>

                <div className="header__icons">
                    {
                        this.state.login &&
                        <div className="header__icon">
                            <i className="fa fa-sign-out" aria-hidden="true" onClick={() => { this.context.logout() }}></i>
                            <label>Wyloguj</label>
                        </div>
                    }
                    <NavLink exact to="/koszyk">
                        <div className='header__icon'>
                            <div className="cart-icon__count">{this.props.cart.count()}</div>
                            <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                            <label>Koszyk</label>
                        </div>
                    </NavLink>
                </div>
            </div>
        );
    }
}