import React from 'react';
import GridComponent from './base/GridComponent';

export default class AdminPanelStatisticsEntries extends GridComponent {
    className = 'adminpanel-entries';
    mapping = {
        date: 'Data',
        ipAddress: 'IP',
        page: 'Działanie'

    };
    mappingCustomGridCellValue = {
        date: (v) => { return v.slice(0, 19).replace('T', ' ')},
    }
    urlApi = '/api/entry';
    urlBack = '/panel';
    readonly = true;
    renderEditDeleteButtonsFlag = false;

    constructor(props, context) {
        super(props, context)
        let dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() - 1);
        this.state.filter = { dateFrom: dateFrom.toLocaleDateString('en-CA') };
    }

    loadData = async () => {

        const parameters = [];
        const values = [];
        if (this.state.filter.count) {
            parameters.push("count");
            values.push(this.state.filter.count);
        }
        if (this.state.filter.dateFrom) {
            parameters.push("dateFrom");
            values.push(this.state.filter.dateFrom);
        }
        if (this.state.filter.dateTo) {
            parameters.push("dateTo");
            values.push(this.state.filter.dateTo);
        }
        const url = this.addParametersToUrl(this.urlApi, parameters, values);

        const response = await this.apiController.get(url);
        if (response.ok) {
            const entities = await response.json();
            this.setState({
                entities: entities
            })
        }
    }

    authorize = () => {
        if (this.context.getToken() === null) {
            this.props.history.push('/login');
            return false;
        }
        return true;
    }

    render() {
        return (
            <div className="grid">
                {this.renderFilters()}
                {this.baseRender()}
            </div>
        )
    }

    renderFilters() {
        return (
            <div className="grid__filters">
                <div className="grid__filter">
                    <label>Liczba wpisów</label>
                    <input type="number" name={"filter.count"} value={this.state.filter["count"]} onChange={(e) => this.handleInputChange(e)} />
                </div>
                <div className="grid__filter">
                    <label>Data od</label>
                    <input type="date" name={"filter.dateFrom"} value={this.state.filter["dateFrom"]} onChange={(e) => this.handleInputChange(e)} />
                </div>
                <div className="grid__filter">
                    <label>Data do</label>
                    <input type="date" name={"filter.dateTo"} value={this.state.filter["dateTo"]} onChange={(e) => this.handleInputChange(e)} />
                </div>
                <button onClick={() => this.refreshEntities()}><i class="fa fa-refresh" aria-hidden="true"></i></button>
            </div>
        )
    }

    renderButtons() {
    }

    async refreshEntities() {
        this.setState({ loading: true });
        if (this.authorize()) {
            await this.loadData();
        }
        this.setState({ loading: false });
    }
}